<template>
  <div class="recurrence-clients">
    <b-breadcrumb
      :items="breadcrumb"
    />

    <b-row>
      <b-col cols="12">
        <b-card-actions
          ref="filters"
          title="Filtros"
          class="form-filters"
          :action-close="false"
          :action-refresh="false"
          :action-collapse="true"
        >
          <b-row>
            <b-col
              cols="3"
            >
              <b-form-group
                label="Gerente"
                label-for="account_manager"
              >
                <b-form-select
                  id="account_manager"
                  v-model="filtersValues.account_manager_id"
                  :options="filtersOptions.account_managers"
                  :disabled="userData.permission_type !== 'root'"
                  @change="results = []"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="3"
            >
              <b-form-group
                label="Data"
                label-for="date_from"
              >
                <b-input-group>
                  <b-form-datepicker
                    v-model="filtersValues.date_from"
                    v-bind="labels['pt-BR'] || {}"
                    show-decade-nav
                    button-variant="outline-primary"
                    :date-format-options="{ day: '2-digit', month: '2-digit', year: 'numeric' }"
                    locale="pt-BR"
                    :disabled="['root', 'sales'].indexOf(userData.permission_type) === -1 || isLoading"
                  />

                  <b-input-group-append v-if="filtersValues.date_from.length > 0">
                    <b-button
                      variant="outline-primary"
                      size="sm"
                      :disabled="['root', 'sales'].indexOf(userData.permission_type) === -1 || isLoading"
                      @click="filtersValues.date_from = ''"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col
              v-if="showDateTo"
              cols="3"
            >
              <b-form-group
                label="Até"
                label-for="date_to"
              >
                <b-input-group>
                  <b-form-datepicker
                    v-model="filtersValues.date_to"
                    v-bind="labels['pt-BR'] || {}"
                    show-decade-nav
                    button-variant="outline-primary"
                    :date-format-options="{ day: '2-digit', month: '2-digit', year: 'numeric' }"
                    locale="pt-BR"
                    :disabled="['root', 'sales'].indexOf(userData.permission_type) === -1 || isLoading"
                  />

                  <b-input-group-append v-if="filtersValues.date_to.length > 0">
                    <b-button
                      variant="outline-primary"
                      size="sm"
                      :disabled="['root', 'sales'].indexOf(userData.permission_type) === -1 || isLoading"
                      @click="filtersValues.date_to = ''"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="text-right">
            <b-col
              cols="12"
              class="form-buttons"
            >
              <b-form-group
                label=""
                label-for="btn-buscar"
                style="margin-top: 20px"
              >
                <b-button
                  id="btn-buscar"
                  variant="primary"
                  @click="getRecurrenceClients()"
                >
                  Buscar
                </b-button>

                <b-button
                  id="btn-limpar"
                  variant="outline-secondary"
                  @click="reset()"
                >
                  Limpar
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-actions>

        <b-card-actions
          ref="cardResults"
          title="Resultados"
          class="form-filters"
          :action-close="false"
          :action-refresh="false"
          :action-collapse="true"
        >
          <b-row>
            <b-col cols="12">
              <b-table
                :busy="isLoading"
                fixed
                :fields="resultFields"
                :items="results"
                sort-icon-left
                no-local-sorting
                :sort-by.sync="filtersValues.sortBy"
                :sort-desc.sync="filtersValues.sortDesc"
                @sort-changed="sortingChanged"
              >
                <template #table-busy>
                  <div class="text-center table-spinner">
                    <b-spinner label="Loading..." />
                    <strong>Carregando...</strong>
                  </div>
                </template>

                <template #head(name)>
                  <div class="text-nowrap">
                    Usuário
                  </div>
                </template>

                <template #head(manager_name)>
                  <div class="text-nowrap">
                    Consultor
                  </div>
                </template>

                <template #head(last_end_of_plan)>
                  <div class="text-nowrap">
                    Final do<br>último plano
                  </div>
                </template>

                <template #head(current_created_plan)>
                  <div class="text-nowrap">
                    Novo Plano
                  </div>
                </template>

                <template #head(diff)>
                  <div class="text-nowrap">
                    Diferença<br> em dias
                  </div>
                </template>

                <template #head(current_payment_type)>
                  <div class="text-nowrap">
                    Pagamento
                  </div>
                </template>

                <!-- Sales -->
                <template #head(end_of_plan)>
                  <div class="text-nowrap">
                    Final do<br> plano
                  </div>
                </template>
                <template #head(created_plan_at)>
                  <div class="text-nowrap">
                    Criação do plano
                  </div>
                </template>
                <template #head(payment_type)>
                  <div class="text-nowrap">
                    Pagamento
                  </div>
                </template>
                <template #head(amount)>
                  <div class="text-nowrap">
                    Total pago
                  </div>
                </template>
                <template #head(amount_per_month)>
                  <div class="text-nowrap">
                    Total pago<br> / percelas
                  </div>
                </template>

                <template #cell(name)="row">
                  <b-link
                    :to="{ name: 'cms-user-form-update', params: { id: row.item.user_id } }"
                  >({{ row.item.user_id }}) {{ row.item.name }}</b-link>
                </template>

                <template #cell(current_payment_type)="row">
                  {{ $t(`financial.columns.payment_type.${row.item.current_payment_type}`) }}
                </template>

                <template #cell(payment_type)="row">
                  {{ $t(`financial.columns.payment_type.${row.item.payment_type}`) }}
                </template>

                <template #cell(current_created_plan)="row">
                  {{ row.item.current_created_plan ? row.item.current_created_plan : '' | date }}
                </template>

                <template #cell(last_end_of_plan)="row">
                  {{ row.item.last_end_of_plan ? row.item.last_end_of_plan : '' | date }}
                </template>

                <template #cell(end_of_plan)="row">
                  {{ row.item.end_of_plan ? row.item.end_of_plan : '' | date }}
                </template>

                <template #cell(created_plan_at)="row">
                  {{ row.item.created_plan_at ? row.item.created_plan_at : '' | date }}
                </template>

                <template #cell(amount)="row">
                  {{ row.item.amount | currency }}
                </template>

                <template #cell(amount_per_month)="row">
                  {{ row.item.amount_per_month | currency }}
                </template>

                <template
                  #row-details="row"
                >
                  <div class="details">
                    {{ row }}
                  </div>
                </template>

              </b-table>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="6">
              <p>Mostrando {{ pages.from }} até {{ pages.to }} de {{ pages.total }} resultados</p>
            </b-col>

            <b-col cols="6">
              <!-- pagination nav -->
              <b-pagination-nav
                v-model="currentPage"
                :number-of-pages="pages.last_page"
                :link-gen="() => '#'"
                align="end"
              />
            </b-col>
          </b-row>
        </b-card-actions>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ManagerService from '@/services/managerService'
import UserService from '@/services/userService'
import moment from 'moment/moment'
import {
  BFormDatepicker,
  BBreadcrumb,
  BRow,
  BCol,
  BLink,
  BTable,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BPaginationNav,
  BSpinner,
  BButton,
} from 'bootstrap-vue'
import toast from '../../../mixins/toast'

export default {
  components: {
    BFormDatepicker,
    BBreadcrumb,
    BCardActions,
    BRow,
    BCol,
    BLink,
    BTable,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BPaginationNav,
    BSpinner,
    BButton,
  },

  mixins: [
    toast,
  ],

  data() {
    return {
      isLoading: true,
      breadcrumb: [
        {
          text: 'Dashboard',
          to: { name: 'cms-dashboard' },
        },
        {
          text: 'Relatórios',
          active: true,
        },
        {
          text: 'Clientes Recorrentes',
          active: true,
        },
      ],
      userService: null,
      userData: null,
      user: null,
      filtersValues: {
        date_from: moment().format('Y-MM-DD'),
        date_to: moment().add(1, 'month').format('Y-MM-DD'),
        role: '',
        account_manager_id: 'success',
        sortBy: 'id',
        sortDesc: true,
      },
      filtersOptions: {
        account_managers: [],
      },
      labels: {
        'pt-BR': {
          labelNoDateSelected: 'Selecione...',
          labelHelp: 'Use o teclado para nagevar nas datas',
        },
      },
      pages: 1,
      currentPage: 1,
      results: [],
    }
  },

  computed: {
    showDateTo() {
      if (Number.isInteger(this.filtersValues.account_manager_id)) {
        const manager = this.managers.find(f => f.id === this.filtersValues.account_manager_id)
        return manager.permission_type === 'sales'
      }

      return this.filtersValues.account_manager_id && this.filtersValues.account_manager_id === 'sales'
    },

    resultFields() {
      let type = ''
      if (Number.isInteger(this.filtersValues.account_manager_id)) {
        const manager = this.managers.find(f => f.id === this.filtersValues.account_manager_id)
        type = manager.permission_type
      } else {
        type = this.filtersValues.account_manager_id
      }

      if (type === 'sales') {
        return [
          { key: 'name', sortable: false },
          { key: 'manager_name', sortable: false },
          { key: 'end_of_plan', sortable: false },
          { key: 'created_plan_at', sortable: false },
          { key: 'payment_type', sortable: false },
          { key: 'amount', sortable: false },
          { key: 'amount_per_month', sortable: false },
        ]
      }

      return [
        { key: 'name', sortable: false },
        { key: 'manager_name', sortable: false },
        { key: 'last_end_of_plan', sortable: false },
        { key: 'current_created_plan', sortable: false },
        { key: 'current_payment_type', sortable: false },
        { key: 'amount', sortable: false },
        { key: 'amount_per_month', sortable: false },
        { key: 'diff', sortable: false },
      ]
    },
  },

  watch: {
    currentPage() {
      this.getRecurrenceClients()
    },
  },

  async created() {
    this.userService = new UserService()

    this.userData = JSON.parse(localStorage.getItem('userData'))
    await this.getManagers()

    if (this.userData.permission_type !== 'root') {
      this.filtersValues.account_manager_id = this.userData.id
    }

    this.getRecurrenceClients()
  },

  methods: {
    reset() {
      this.currentPage = 1
    },

    sortingChanged(ctx) {
      if (ctx.sortBy !== '') {
        this.filtersValues.sortBy = ctx.sortBy
        this.filtersValues.sortDesc = ctx.sortDesc
        this.getRecurrenceClients()
      }
    },

    async getManagers() {
      return this.userService.getAndStoreManagers().then(managers => {
        this.managers = managers

        const success = [
          { value: 'success', text: 'Todos' },
          ...this.managers.filter(f => f.permission_type === 'success' && f.deleted_at === null).reduce((c, r) => [
            ...c,
            ...[
              { value: r.id, text: r.name },
            ],
          ], []).sort((a, b) => {
            if (a.text > b.text) {
              return 1
            }

            return (b.text > a.text) ? -1 : 0
          }),
        ]

        const sales = [
          { value: 'sales', text: 'Todos' },
          ...this.managers.filter(f => f.permission_type === 'sales' && f.deleted_at === null).reduce((c, r) => [
            ...c,
            ...[
              { value: r.id, text: r.name },
            ],
          ], []).sort((a, b) => {
            if (a.text > b.text) {
              return 1
            }

            return (b.text > a.text) ? -1 : 0
          }),
        ]

        this.filtersOptions.account_managers = [
          {
            label: 'Sucesso',
            options: success,
          },
          {
            label: 'Vendas',
            options: sales,
          },
        ]
      })
    },

    getRecurrenceClients() {
      this.isLoading = true

      const filters = { ...this.filtersValues }

      if (Number.isInteger(this.filtersValues.account_manager_id)) {
        const manager = this.managers.find(f => f.id === this.filtersValues.account_manager_id)
        filters.role = manager.permission_type
      } else {
        filters.role = filters.account_manager_id
        filters.account_manager_id = 'all'
      }

      ManagerService.getRecurrenceClients({
        ...filters,
        page: this.currentPage,
      }).then(response => {
        if (response.data !== null) {
          this.results = response.data.data['recurrence-clients'].data
          this.pages = { ...response.data.data['recurrence-clients'], data: null }
        }
        this.isLoading = false
      }).catch(() => {
        this.toastDanger('Erro ao buscar os dados')
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .recurrence-clients {
    .header-card {
      margin-bottom: 20px;
    }

    .b-table {
      td {
        padding: 0.5rem 1rem;
      }
    }

    .form-buttons button {
      margin-right: 10px;
    }
  }
</style>
